/* You can add global styles to this file, and also import other style files */
@import 'assets/fonts/inter/inter.css'
@import "primeng/resources/themes/lara-light-blue/theme.css"
@import "primeng/resources/primeng.css"
  
@import 'flag-icons/sass/flag-icons.scss'

@tailwind base
@tailwind components
@tailwind utilities

// Input type = number
input[type=number]
  -moz-appearance: textfield
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

.application-logo 
  max-height: 24px
  width: 147px
  object-fit: cover

.bg-white-new
  background: rgb(243 244 246)

.profile-image-class
  height: 80px
  width: 80px
  border-radius: 50%
  object-fit: cover

.svg-icon-class-Xxsmall
  height: 10px
.svg-icon-class-Xsmall
  height: 14px
.svg-icon-class-small
  height: 18px
.svg-icon-small
  height: 23px
.svg-icon-class
  height: 24px
.svg-icon-class-md
  height: 28px
.svg-icon-class-rank
  height: 18px
.svg-icon-class-bidder
  height: 22px

.floating-button
  position: absolute
  bottom: 24px
  right: 24px

.fixed-footer
  position: fixed
  left: 20%
  right: 20%
  bottom: 5%

input:focus, select:focus
    border: 2px solid rgb(156 163 175)
    outline: none 
    border-radius: 6px 

.is-invalid
  outline: 2px solid rgb(248 113 113) !important
  background-color: rgb(254 226 226) !important

.p-dialog .p-dialog-content
  padding: 0rem !important

.p-autocomplete.p-component.p-inputwrapper.p-inputwrapper-filled.p-focus.p-inputwrapper-focus 
  display: grid !important
  height: 32px !important 
  padding: 0px !important
  outline: none !important
  width: 100%
.tr-vertical-text
  transform: rotate(-180deg)
  color: rgba(255, 255, 255, 0.70)
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 16px /* 114.286% */
  letter-spacing: 0.14px
  writing-mode: vertical-lr
  text-orientation: mixed
.footer
  position: absolute
  width: 100%
  bottom: 0
  background: white
  display: flex
  align-items: flex-end
  justify-content: flex-end
  align-content: stretch


.p-datatable
   border-radius: 8px !important

.p-datatable .p-datatable-thead > tr > th
    padding: 8px !important
    color: #475569 !important
    font-size: 12px
    background-color: rgb(248 250 252)
    font-weight: 600
    border-bottom: 1px solid #D1D5DB

.p-datatable .p-element.p-datatable-tbody > tr > td
    padding: 8px !important
    font-size: 12px
    color: #1e293b !important
    font-weight: 440
.p-datatable .p-element.p-datatable-tbody > tr
    border-bottom: 1px solid #D1D5DB
.p-datatable .p-element.p-datatable-tbody > tr:last-child
    border-bottom: 0px solid #D1D5DB

.p-icon.p-sortable-column-icon
  height: 12px !important
  width: 12px !important
  display: flex !important
  align-items: center !important
  margin-top: 3px !important

.p-inputtext
  font-size: 14px
  font-weight: 500 !important
  color: #4b5563 !important

.form-select
  font-size: 14px !important
  font-weight: 500 !important
  color: #4b5563 !important

.p-autocomplete.p-component.p-inputwrapper.p-inputwrapper-filled
  width: 96%
  padding-top: 6px

.p-datatable-wrapper
  border-radius: 8px

.overflow-class-small-sidebar
  overflow: hidden
  text-overflow: ellipsis
  width: 50px
  white-space: nowrap